define("discourse/plugins/discourse-gamification/discourse/routes/gamification-leaderboard-index", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _service, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.service)(),
    model() {
      return (0, _ajax.ajax)(`/leaderboard`).then(response => {
        return response;
      }).catch(() => this.router.replaceWith("/404"));
    }
  });
});