define("discourse/plugins/discourse-gamification/discourse/components/gamification-score", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.site.default_gamification_leaderboard_id}}
    <LinkTo
      @route="gamificationLeaderboard.byName"
      @model={{this.site.default_gamification_leaderboard_id}}
      class="gamification-score__link"
    >
      {{fullnumber this.model.gamification_score}}
    </LinkTo>
  {{else}}
    {{fullnumber this.model.gamification_score}}
  {{/if}}
  */
  {
    "id": "VcVrTzwJ",
    "block": "[[[41,[30,0,[\"site\",\"default_gamification_leaderboard_id\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"gamification-score__link\"]],[[\"@route\",\"@model\"],[\"gamificationLeaderboard.byName\",[30,0,[\"site\",\"default_gamification_leaderboard_id\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"model\",\"gamification_score\"]]],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,2],[[30,0,[\"model\",\"gamification_score\"]]],null]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"link-to\",\"fullnumber\"]]",
    "moduleName": "discourse/plugins/discourse-gamification/discourse/components/gamification-score.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: "span",
    classNames: "gamification-score"
  }));
});